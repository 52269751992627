import React from "react"
import { graphql } from "gatsby"
import RawMarkdownPage from "../../templates/raw-markdown-page"

const Agb = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  return <RawMarkdownPage frontmatter={frontmatter} html={html} />
}

export const AgbDePageQuery = graphql`
  query AgbDePage {
    markdownRemark(
      fields: { slug: { eq: "/de/agb/" }, langKey: { eq: "de" } }
    ) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`

export default Agb
