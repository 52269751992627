import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Content, HTMLContent } from "../components/content"
import PropTypes from "prop-types"

export const RawMarkdownPageTemplate = ({ html, contentComponent }) => {
  const ContentComponent = contentComponent || Content
  return (
    <section className="container mx-auto px-5 markdown">
      <ContentComponent content={html} />
    </section>
  )
}

RawMarkdownPageTemplate.defaultProps = {
  html: "",
  contentComponent: null,
}

RawMarkdownPageTemplate.propTypes = {
  html: PropTypes.string,
  contentComponent: PropTypes.func,
}

const RawMarkdownPage = ({ frontmatter, html }) => {
  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      <RawMarkdownPageTemplate html={html} contentComponent={HTMLContent} />
    </Layout>
  )
}

RawMarkdownPage.defaultProps = {
  html: "",
}

RawMarkdownPage.propTypes = {
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  html: PropTypes.string,
}

export default RawMarkdownPage
