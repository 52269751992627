import React from "react"
import PropTypes from "prop-types"

export const HTMLContent = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
)

export const Content = ({ content }) => <div>{content}</div>

Content.propTypes = {
  content: PropTypes.node.isRequired,
}

HTMLContent.propTypes = Content.propTypes
